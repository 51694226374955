export default {
    defense: {
        missile_block: 'Ракетный блок',
        infrared_laser: 'Инфракрасный лазер',
        ultraviolet_laser: 'Ультрафиолетовый лазер',
        graviton_weapon: 'Гравитонное орудие',
        photon_cannon: 'Фотонная пушка',
        lepton_cannon: 'Лептонная пушка',
        small_energy_shield: 'Малый энергокупол',
        big_energy_shield: 'Большой энергокупол'
    },
    spaceships: {
        shuttle: 'Шаттл',
        fighter: 'Истребитель',
        transport: 'Транспорт',
        // energodrone: 'Энергодрон',
        attacker: 'Штурмовик',
        frigate: 'Фрегат',
        galaction: 'Галактион',
        destroyer: 'Разрушитель',
        bombardier: 'Бомбардир',
        colossus: 'Колосс',
        scout: 'Разведзонд',
        collector: 'Коллектор',
        pioneer: 'Первопроходец',
        corvette: 'Корвет'
    },
    technologies: {
        planet_scanning: 'Планетарное сканирование',
        navigation: 'Навигация',
        armament: 'Вооружения',
        ship_protection: 'Защита кораблей',
        energy_shields: 'Энергетические поля',
        energetics: 'Энергетика',
        subspace_travel: 'Перемещение в подпространстве',
        baryonic_engine: 'Барионный двигатель',
        annihilation_engine: 'Аннигиляционный двигатель',
        subspace_engine: 'Подпространственный двигатель',
        military_lasers: 'Боевые лазеры',
        photonic_weapon: 'Фотонное оружие',
        leptonic_weapon: 'Лептонное оружие',
        tachyon_scan: 'Тахионное сканирование',
        planet_exploration: 'Освоение планет',
        vibrotron: 'Вибротрон'
    }
}
